<template>
  <div>
    <easiLoading v-if="isLoading" />
    <PreviewInvoice
      v-else
      publicPage
      showPay
      :formDetails="details.invoice"
      :bankDetails="details.companyBankDetails"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import PreviewInvoice from "@/components/BookKeeping/Invoice/PreviewInvoice.vue";
import { useRoute, useRouter } from "vue-router";
import { GETANINVOICEDETAILED } from "@/services/book-keeping/invoice";

import { useToast } from "vue-toastification";
const toast = useToast();

const route = useRoute();
const router = useRouter();

const invoiceId = computed(() => route.query.id);
const companyId = computed(() => route.query.companyId);

const isLoading = ref(true);
const details = ref({});
onMounted(async () => {
  isLoading.value = true;

  const res = await GETANINVOICEDETAILED(invoiceId.value,companyId.value);
  if (res) {
    details.value = res;
    isLoading.value = false;
  } else {
    toast.error("Invoice not found");
    router.push("/");
  }
});
</script>

<style lang="scss" scoped></style>
